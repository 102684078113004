var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"px-4",staticStyle:{"background":"rgba(255, 255, 255, 0.7)"},attrs:{"elevation":"0"}},[_c('v-card-subtitle',{staticClass:"font-weight-bold subtitle-1 accent--text pb-0"},[_vm._v(" 異常統計 ("+_vm._s(_vm.abnormaList.length)+") ")]),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.abnormaList,"hide-default-footer":"","mobile-breakpoint":"0","loading":_vm.loadingSearch,"loading-text":"載入中...","page":_vm.paginate.index,"items-per-page":_vm.paginate.count,"no-data-text":"( 無資料 )"},on:{"update:page":function($event){return _vm.$set(_vm.paginate, "index", $event)}},scopedSlots:_vm._u([{key:"item.className",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(value || '-')+" ")]}},{key:"item.deviceNumber",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(value || '-')+" ")]}},{key:"item.startTime",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(value ? _vm.moment(value).format('YYYY-MM-DD HH:mm') : '-')+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"primary--text",attrs:{"text":""},on:{"click":function($event){return _vm.toIssueReport(item)}}},[_vm._v(" 報修"),_c('span',[_c('v-icon',{attrs:{"color":"primary","small":""}},[_vm._v("mdi-arrow-right")])],1)])]}}],null,true)}),_c('div',{staticClass:"text-center pt-2"},[(_vm.totalPage > 1)?_c('v-pagination',{attrs:{"length":_vm.totalPage,"color":"accent"},model:{value:(_vm.paginate.index),callback:function ($$v) {_vm.$set(_vm.paginate, "index", $$v)},expression:"paginate.index"}}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }