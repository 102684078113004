var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"color":"transparent","elevation":"0"}},[_c('v-card-title',{staticClass:"px-0 font-weight-bold accent--text"},[_vm._v("異常設備篩選查詢")]),_c('v-card-text',{staticClass:"px-0"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"prepend-inner-icon":"mdi-calendar","readonly":"","required":"","rules":[
                function (v) { return !!v || '選擇時間區間'; },
                function (v) { return v.indexOf('~') > -1 || '請選擇時間結束日期'; }
              ],"hide-details":"","solo":"","flat":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_c('span',{staticClass:"red--text"},[_vm._v("*")]),_vm._v("異常發生時間區間")])]},proxy:true}],null,true),model:{value:(_vm.dateRange),callback:function ($$v) {_vm.dateRange=$$v},expression:"dateRange"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.datePicker.show),callback:function ($$v) {_vm.$set(_vm.datePicker, "show", $$v)},expression:"datePicker.show"}},[_c('v-date-picker',{ref:"picker",attrs:{"no-title":"","range":"","scrollable":"","color":"primary","max":_vm.moment().format('YYYY-MM-DD'),"min":_vm.moment()
                .subtract(3, 'years')
                .add(1, 'days')
                .format('YYYY-MM-DD')},model:{value:(_vm.datePicker.range),callback:function ($$v) {_vm.$set(_vm.datePicker, "range", $$v)},expression:"datePicker.range"}})],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"subtitle-1 grey--text font-weight-bold mb-1"},[_vm._v(" 異常裝置類型 ")]),_c('v-row',_vm._l((_vm.meterType),function(type){return _c('v-col',{key:type.id,staticClass:"px-6",attrs:{"cols":"auto"}},[_c('v-checkbox',{staticClass:"mt-0 pt-0",attrs:{"label":type.name,"hide-details":"","color":"#0065c9","value":type.id},model:{value:(_vm.selectMeterTypes),callback:function ($$v) {_vm.selectMeterTypes=$$v},expression:"selectMeterTypes"}})],1)}),1)],1),_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"py-6 px-14 accent--text font-weight-bold",attrs:{"color":"ems-aquamarine","disabled":!_vm.datePicker.range[0] || !_vm.datePicker.range[1]},on:{"click":_vm.getDisconnectList}},[_vm._v("開始查詢")])],1),(_vm.isSearch)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.disconnectList,"mobile-breakpoint":"0","loading":_vm.loadingSearch,"loading-text":"載入中...","item-class":_vm.rowBackgroundClass,"id":"table","page":_vm.query.index,"items-per-page":_vm.query.paginateCount,"hide-default-footer":""},on:{"update:page":function($event){return _vm.$set(_vm.query, "index", $event)}},scopedSlots:_vm._u([{key:"item.type",fn:function(ref){
                var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("showName")(value,_vm.meterType))+" ")]}},{key:"item.startTime",fn:function(ref){
                var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.moment(value).format('YYYY-MM-DD HH:mm'))+" ")]}},{key:"item.endTime",fn:function(ref){
                var value = ref.value;
return [_vm._v(" "+_vm._s(value ? _vm.moment(value).format('YYYY-MM-DD HH:mm') : '未修復')+" ")]}},{key:"item.action",fn:function(ref){
                var item = ref.item;
return [(!item.endTime)?_c('v-btn',{staticClass:"primary--text",attrs:{"text":""},on:{"click":function($event){return _vm.toIssueReport(item)}}},[_vm._v(" 報修"),_c('span',[_c('v-icon',{attrs:{"color":"primary","small":""}},[_vm._v("mdi-arrow-right")])],1)]):_vm._e()]}}],null,true)}),_c('div',{staticClass:"text-center pt-2"},[_c('v-pagination',{attrs:{"length":_vm.totalPage,"color":"accent"},on:{"input":_vm.getDisconnectList},model:{value:(_vm.query.index),callback:function ($$v) {_vm.$set(_vm.query, "index", $$v)},expression:"query.index"}})],1)],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }