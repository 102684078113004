<template>
  <v-card
    elevation="0"
    style="background: rgba(255, 255, 255, 0.7)"
    class="px-4"
  >
    <v-card-subtitle class="font-weight-bold subtitle-1 accent--text pb-0">
      異常統計&nbsp;({{ abnormaList.length }})
    </v-card-subtitle>
    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="abnormaList"
        hide-default-footer
        mobile-breakpoint="0"
        :loading="loadingSearch"
        loading-text="載入中..."
        :page.sync="paginate.index"
        :items-per-page="paginate.count"
        no-data-text="( 無資料 )"
      >
        <template v-slot:[`item.className`]="{ value }">
          {{ value || '-' }}
        </template>
        <template v-slot:[`item.deviceNumber`]="{ value }">
          {{ value || '-' }}
        </template>
        <template v-slot:[`item.startTime`]="{ value }">
          {{ value ? moment(value).format('YYYY-MM-DD HH:mm') : '-' }}
        </template>
        <template v-slot:item.action="{ item }">
          <v-btn class="primary--text" text @click="toIssueReport(item)">
            報修<span
              ><v-icon color="primary" small>mdi-arrow-right</v-icon></span
            >
          </v-btn>
        </template>
      </v-data-table>
      <div class="text-center pt-2">
        <v-pagination
          v-if="totalPage > 1"
          v-model="paginate.index"
          :length="totalPage"
          color="accent"
        ></v-pagination>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import Device from '@/api/ems/Device'

export default {
  components: {},
  data() {
    return {
      originAbnormaList: [],
      loadingSearch: false,
      paginate: {
        index: 1,
        count: 3
      }
    }
  },
  computed: {
    ...mapGetters({
      abnormaType: 'device/abnormaType',
      meterType: 'device/meterType',
      isIlan: 'page/isIlan'
    }),
    abnormaList() {
      return this.originAbnormaList
        .map((item) => {
          item.meterType = this.meterType.find((type) => type.id === item.type)
          return item
        })
        .filter((item) => item.meterType)
    },
    headers() {
      const headerList = [
        { text: '教室', value: 'className' },
        { text: '裝置序號', value: 'deviceNumber' },
        { text: '裝置類型', value: 'meterType.name' },
        { text: '異常發生時間', value: 'startTime' }
      ]
      if (!this.isIlan) {
        headerList.push({
          text: '',
          value: 'action',
          width: '50',
          sortable: false
        })
      }
      return headerList
    },
    totalPage() {
      return Math.ceil(this.abnormaList.length / 3)
    }
  },
  mounted() {
    this.getDisconnectList()
  },
  filters: {
    showName: function (value, items) {
      if (!value) return '-'
      const type = items.find((item) => item.id === value)
      return type ? type.name : 'null'
    }
  },
  methods: {
    async getDisconnectList() {
      try {
        this.loadingSearch = true
        const { data } = await Device.currentDisconnectList(
          this.$route.params.tagId
        )
        this.originAbnormaList = data
      } catch (error) {
        console.error(error.response)
      } finally {
        this.loadingSearch = false
      }
    },
    toIssueReport(item) {
      // todo add issue content as params
      this.$router.push({ name: 'IssueReport' })
    }
  }
}
</script>

<style lang="scss" scoped>
*::v-deep {
  &.v-data-table {
    background-color: transparent !important;
  }
  &.v-data-table > .v-data-table__wrapper > table {
    border-collapse: separate;
    border-spacing: 0 0.5em;
    th,
    td {
      border-bottom: none !important;
    }
    thead > tr > th {
      background-color: #acc0dd;
      color: #fff;
    }
    td {
      background-color: #fff;
      color: var(--v-accent-base);
      font-size: 1rem !important;
    }
  }
}
</style>
