<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="tableItems"
      :items-per-page="tableItems.length"
      hide-default-footer
      mobile-breakpoint="0"
    >
      <template v-slot:body="{ items, headers }">
        <tbody>
          <tr v-for="item in items" :key="item.name">
            <td
              v-for="header in headers"
              :key="header.value"
              :align="header.align"
            >
              <span v-if="item.id === 'all'">{{ item[header.value] }}</span>
              <span v-else-if="header.value === 'name'">{{
                item[header.value]
              }}</span>
              <span
                v-else-if="
                  !meterTypeAllCountItems[header.value] ||
                  meterTypeAllCountItems[header.value] === 0
                "
                >N/A</span
              >
              <span v-else-if="!item[header.value] && item[header.value] !== 0"
                >N/A</span
              >
              <span v-else-if="item[header.value] === 0">0</span>
              <span v-else>
                {{ item[header.value] }}
              </span>
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: undefined,
  mixins: [],
  components: {},
  props: {
    statusList: {
      type: Array,
      default: () => []
    }
  },
  data: function () {
    return {}
  },
  computed: {
    ...mapGetters({
      meterType: 'device/meterType',
      abnormaType: 'device/abnormaType'
    }),
    headers() {
      if (!this.meterType) {
        return []
      }
      const items = [
        {
          text: '',
          align: 'start',
          value: 'name'
        }
      ]
      this.meterType.forEach((type) => {
        items.push({
          text: type.name,
          align: 'center',
          value: type.id
        })
      })
      return items
    },
    tableItems() {
      if (!this.abnormaType || !this.meterType) {
        return []
      }
      const items = this.abnormaType.map((item) => {
        this.meterType.forEach((meter) => {
          const findMeterData = this.statusList.find(
            (data) => meter.id === data.type
          )

          item[`${meter.id}`] =
            findMeterData && findMeterData[`${item.id}Count`]
              ? findMeterData[`${item.id}Count`]
              : 0
        })
        return item
      })
      const totalData = this.meterTypeAllCountItems
      totalData.id = 'all'
      totalData.name = '安裝總數'
      items.splice(0, 0, totalData)
      return items
    },
    meterTypeAllCountItems() {
      const meterData = {}
      this.meterType.forEach((meter) => {
        const findMeterData = this.statusList.find(
          (data) => meter.id === data.type
        )
        meterData[meter.id] = findMeterData ? findMeterData.allCount : 0
      })
      return meterData
    }
  },
  watch: {},
  created: function () {},
  mounted: function () {},
  destroyed() {},
  methods: {}
}
</script>

<style lang="scss" scoped>
*::v-deep {
  &.v-data-table {
    background-color: transparent !important;
  }
  &.v-data-table > .v-data-table__wrapper > table {
    border-collapse: separate;
    border-spacing: 0 0.5em;
    th,
    td {
      border-bottom: none !important;
    }
    thead > tr > th {
      background-color: #acc0dd;
      color: #fff;
    }
    td {
      background-color: #fff;
      color: var(--v-accent-base);
      font-size: 1rem !important;
    }
  }
}
</style>
