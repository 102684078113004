<template>
  <v-card color="transparent" elevation="0">
    <v-card-title class="px-0 font-weight-bold accent--text"
      >異常設備篩選查詢</v-card-title
    >
    <v-card-text class="px-0">
      <v-row>
        <v-col cols="12" sm="6" md="3">
          <v-menu
            ref="menu"
            v-model="datePicker.show"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateRange"
                prepend-inner-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                required
                :rules="[
                  (v) => !!v || '選擇時間區間',
                  (v) => v.indexOf('~') > -1 || '請選擇時間結束日期'
                ]"
                hide-details=""
                solo
                flat
              >
                <template v-slot:label>
                  <div><span class="red--text">*</span>異常發生時間區間</div>
                </template>
              </v-text-field>
            </template>
            <v-date-picker
              ref="picker"
              v-model="datePicker.range"
              no-title
              range
              scrollable
              color="primary"
              :max="moment().format('YYYY-MM-DD')"
              :min="
                moment()
                  .subtract(3, 'years')
                  .add(1, 'days')
                  .format('YYYY-MM-DD')
              "
            >
            </v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <v-row>
        <!-- <v-col cols="12">
          <v-checkbox
            v-model="query.isOver48Hr"
            label="只顯示48小時以上異常"
            hide-details
            class="mt-0 pt-0"
            color="#0065c9"
          ></v-checkbox>
        </v-col> -->
        <v-col cols="12">
          <div class="subtitle-1 grey--text font-weight-bold mb-1">
            異常裝置類型
          </div>
          <v-row>
            <v-col
              cols="auto"
              v-for="type in meterType"
              :key="type.id"
              class="px-6"
            >
              <v-checkbox
                v-model="selectMeterTypes"
                :label="type.name"
                hide-details
                class="mt-0 pt-0"
                color="#0065c9"
                :value="type.id"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" class="text-center">
          <v-btn
            class="py-6 px-14 accent--text font-weight-bold"
            color="ems-aquamarine"
            :disabled="!datePicker.range[0] || !datePicker.range[1]"
            @click="getDisconnectList"
            >開始查詢</v-btn
          >
        </v-col>
        <v-col cols="12" v-if="isSearch">
          <v-data-table
            :headers="headers"
            :items="disconnectList"
            mobile-breakpoint="0"
            :loading="loadingSearch"
            loading-text="載入中..."
            :item-class="rowBackgroundClass"
            id="table"
            :page.sync="query.index"
            :items-per-page="query.paginateCount"
            hide-default-footer
          >
            <template v-slot:[`item.type`]="{ value }">
              {{ value | showName(meterType) }}
            </template>
            <template v-slot:[`item.startTime`]="{ value }">
              {{ moment(value).format('YYYY-MM-DD HH:mm') }}
            </template>
            <template v-slot:[`item.endTime`]="{ value }">
              {{ value ? moment(value).format('YYYY-MM-DD HH:mm') : '未修復' }}
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <v-btn
                v-if="!item.endTime"
                class="primary--text"
                text
                @click="toIssueReport(item)"
              >
                報修<span
                  ><v-icon color="primary" small>mdi-arrow-right</v-icon></span
                >
              </v-btn>
            </template>
          </v-data-table>
          <div class="text-center pt-2">
            <v-pagination
              v-model="query.index"
              :length="totalPage"
              color="accent"
              @input="getDisconnectList"
            ></v-pagination>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import Device from '@/api/ems/Device'

export default {
  name: undefined,
  mixins: [],
  components: {},
  props: {
    schoolId: {
      type: String,
      required: true
    }
  },
  data: function () {
    return {
      query: {
        // isOver48Hr: false,
        index: 1,
        paginateCount: 10
      },
      totalPage: 1,
      selectMeterTypes: [],
      disconnectList: [],
      isSearch: false,
      loadingSearch: false,
      datePicker: {
        show: false,
        range: []
      }
    }
  },
  computed: {
    ...mapGetters({
      meterType: 'device/meterType',
      isIlan: 'page/isIlan'
    }),
    headers() {
      let headerList = [
        {
          text: '建築',
          align: 'start',
          value: 'buildingName',
          sortable: false
        },
        { text: '樓層', value: 'floorName', sortable: false },
        { text: '教室', value: 'className', sortable: false },
        { text: '空間編號', value: 'classNumber', sortable: false },
        { text: '裝置序號', value: 'deviceNumber', sortable: false },
        { text: '裝置類型', value: 'type', sortable: false },
        // { text: '異常型態', value: 'status' },
        { text: '異常開始時間', value: 'startTime', sortable: false },
        { text: '異常結束時間', value: 'endTime', sortable: false }
      ]
      if (!this.isIlan)
        headerList.push({
          text: '',
          value: 'action',
          width: '50',
          sortable: false
        })
      return headerList
    },
    dateRange() {
      if (this.datePicker.range.length === 0) return ''
      const dates = this.datePicker.range
        .slice()
        .sort(
          (a, b) =>
            parseFloat(moment(a).format('x'), 10) -
            parseFloat(moment(b).format('x'), 10)
        )
      return dates.join('~')
    },
    params() {
      return {
        startDate: this.dateRange.split('~')[0],
        endDate: this.dateRange.split('~')[1],
        ...this.query,
        deviceTypes: this.selectMeterTypes.join(',')
      }
    }
  },
  watch: {},
  filters: {
    showName: function (value, items) {
      if (!value) return null
      const type = items.find((item) => item.id === value)
      return type ? type.name : null
    }
  },
  created: function () {},
  mounted: function () {},
  destroyed() {},
  methods: {
    getDisconnectList() {
      this.isSearch = true
      this.loadingSearch = true
      Device.disconnectList(this.schoolId, this.params)
        .then((response) => {
          this.disconnectList = response.data.data
          this.totalPage = response.data.allIndex || 1
        })
        .catch((error) => {
          console.error(error.response)
        })
        .finally(() => {
          this.$vuetify.goTo('#table')
          this.loadingSearch = false
        })
    },
    toIssueReport(item) {
      // todo add issue content as params
      this.$router.push({ name: 'IssueReport' })
    },
    rowBackgroundClass(item) {
      return item.endTime ? '' : 'errorClass'
    }
  }
}
</script>

<style lang="scss" scoped>
*::v-deep {
  label.v-label {
    color: var(--v-accent-base);
  }
  &.v-data-table {
    background-color: transparent !important;
  }
  &.v-data-table > .v-data-table__wrapper > table {
    border-collapse: separate;
    border-spacing: 0 0.5em;
    th,
    td {
      border-bottom: none !important;
    }
    thead > tr > th {
      background-color: #acc0dd;
      color: #fff;
    }
    td {
      background-color: rgba(255, 255, 255, 0.7);
      color: var(--v-accent-base);
      font-size: 1rem !important;
    }
    tr.errorClass {
      border: 1px solid #ed3a4b;
      td {
        background-color: rgb(252, 224, 224, 0.7);
      }
    }
  }
}
</style>
