<template>
  <v-sheet width="100%" max-width="1266px" class="mx-auto" color="transparent">
    <div class="pa-3">
      <v-row>
        <v-col cols="12" sm="4">
          <v-card
            color="#1a5878"
            elevation="0"
            :height="!$vuetify.breakpoint.xs ? '65%' : ''"
          >
            <v-sheet
              max-height="33%"
              color="transparent"
              class="ems-light-grey--text pa-4"
            >
              <div class="subtitle-1 font-weight-bold">異常設備總數量</div>
              <div class="font-weight-bold text-h3">
                {{ deviceAbnormalCount }}
              </div>
            </v-sheet>
            <v-sheet color="transparent" class="ems-light-grey--text pa-4">
              <div class="subtitle-1 font-weight-bold">
                持續兩天以上異常設備數量
              </div>
              <div class="font-weight-bold text-h3">
                {{ disconnectedTwoDayCount }}
              </div>
            </v-sheet>
          </v-card>
          <v-card
            color="success"
            class="mt-2"
            elevation="0"
            :height="!$vuetify.breakpoint.xs ? '33.5%' : ''"
          >
            <v-card-text>
              <div class="accent--text subtitle-1 font-weight-bold">
                即時連線率
              </div>
              <div class="font-weight-bold text-h3 white--text">
                {{ numeral(availability).format('0.00%') }}
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="8">
          <abnormalCard :statusList="statusList" style="height: 100%" />
        </v-col>
        <v-col cols="12">
          <statisticsTable :statusList="statusList" />
        </v-col>
        <v-col cols="12">
          <searchCard :schoolId="tagId" id="search" />
        </v-col>
      </v-row>
    </div>
  </v-sheet>
</template>

<script>
import { mapGetters } from 'vuex'
import Device from '@/api/ems/Device'
import statisticsTable from './statisticsTable'
import searchCard from './searchCard'
import abnormalCard from './abnormalCard'
import IPC from '@/api/ems/IPC'

export default {
  name: 'DamagedIndex',
  mixins: [],
  components: {
    statisticsTable,
    searchCard,
    abnormalCard
  },
  props: {
    tagId: {
      type: String,
      required: true
    }
  },
  data: function () {
    return {
      statusList: []
    }
  },
  computed: {
    ...mapGetters({
      abnormaType: 'device/abnormaType',
      meterType: 'device/meterType'
    }),
    availability() {
      return (this.deviceCount - this.deviceAbnormalCount) / this.deviceCount
    },
    deviceCount() {
      return this.statusList.reduce(
        (accumulator, currentValue) => accumulator + currentValue.allCount,
        0
      )
    },
    deviceAbnormalCount() {
      return this.abnormaType.reduce((accumulator, type) => {
        const statusCount = this.statusList
          .filter((status) => {
            if (status.type === 'IPC') {
              return false
            }
            const meterType = this.meterType.find(
              (type) => type.id === status.type
            )
            if (!meterType) {
              return false
            }
            return true
          })
          .reduce((statusAccumulator, status) => {
            return statusAccumulator + (status[`${type.id}Count`] || 0)
          }, 0)
        return accumulator + statusCount
      }, 0)
    },
    disconnectedTwoDayCount() {
      return this.statusList.reduce((statusAccumulator, status) => {
        return statusAccumulator + (status.disconnectedTwoDayCount || 0)
      }, 0)
    }
  },
  async mounted() {
    if (this.$route.hash === '#search') this.$vuetify.goTo('#search')
    await this.getStatusList()
    await this.getIPCStatus()
  },
  methods: {
    async getStatusList() {
      try {
        const { data } = await Device.statusList(this.tagId)
        this.statusList = data.deviceStatusCountList.filter(
          (i) => i.type !== 'HMI-ALERT'
        )
      } catch (error) {
        console.error(error)
      } finally {
        this.overlay = false
      }
    },
    async getIPCStatus() {
      try {
        const { data } = await IPC.getSchoolIPC(this.tagId)
        this.statusList = this.statusList.concat([
          {
            type: 'IPC',
            disconnectedCount: data.isConnected ? 0 : 1,
            allCount: 1
          }
        ])
      } catch (error) {
        console.error(error)
      }
    }
  }
}
</script>

<style></style>
